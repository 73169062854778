import React from 'react';
import { Parallax } from 'react-parallax';

export default function GameTitle(props) {
  return (
      <div className="title">
          <Parallax bgImage={props.bgImage} strength={200}>
              <div style={{height: 200, background: "rgba(0,0,0," + props.overlayOpacity + ")"}}>
                  <table className='gameTitle'>
                      <tr>
                          <td> <img src={props.gameIcon} alt=""/> </td>
                          <td> <h1>{props.title}</h1> </td>
                      </tr>
                  </table>
              </div>
          </Parallax>
      </div>
  );
}