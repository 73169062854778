import React from 'react';
import { Link } from "gatsby"
import GameTitle from '../../components/GameTitle';
import Layout from '../../components/Layout'
import * as style from '../../styles/gamePage.module.css'
import { Helmet } from 'react-helmet';

export default function ShooterSpaceAge() {
  return (
    <Layout>
      <Helmet>
        <meta charSet='urtf-8'/>
        <title>Venture Games - Shooter: Space Age!</title>
      </Helmet>

      <GameTitle title="Shooter: Space Age!" bgImage="/ShooterSpaceAge-Background.png" gameIcon="/ShooterGameIcon.png"/>
      <section className={style.descriptionSection}>
        <iframe 
        width="560"
        height="315"
        src="https://www.youtube.com/embed/1LDHNpTKSk0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
        </iframe>
        <p>
          Blast your way through waves of enemy ships!<br/>
          Dodge the incoming Asteroids!<br/>
          Collect coins and upgrade to survive for longer and defeat more of your enemies!
          How long can you survive for?! <br/>
          <br/>
          Shooter: Space Age is a fast, exciting and challenging 2D arcade style space shooter which is inspired by the old arcade space shooter games but with a modern spin. The objective is to blast your way through and endless wave of enemies across the galaxy and survive for as long as you can!<br/>
          <Link className="btn" to="#downloadSection">Download Here</Link>
        </p>
      </section>
      <section className={style.downloadSection} id="downloadSection">
        <h2>DOWNLOAD</h2>
        <p>Download here for FREE for mobile devices!</p>
        <Link className={"btn " + style.downloadLinkButton} to="https://play.google.com/store/apps/details?id=com.VentureGames.ShooterSpaceAge"><div><p>Google Play</p><img src="/GooglePlayLogo.png" alt=""/></div></Link>
        <Link className={"btn " + style.downloadLinkButton} to="https://apps.apple.com/us/app/shooter-space-age/id1538871239"><div><p>App Store</p><img src="/AppleLogo.png" alt=""/></div></Link>
      </section>
    </Layout>
  );
}
